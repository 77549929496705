import Vue from 'vue'

Vue.filter('simNumber', (value) => {
  if (value) {
    // console.log('sim number', value)
    let tempNumber = `●●●●●●●●${value.slice(value.length - 6)}`
    // let tempNumber = `###########${value.substring(value.length, 6)}`
    // let tempNumber = '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-' + value.substring(6, 10)

    // console.log('after', tempNumber)
    return tempNumber
  }
})

Vue.filter('USPhoneNumber', (value) => {
  if (value) {
    let tempNumber = '(' + '●●●' + ') ' + '●●●' + '-' + value.substring(6, 10)
    return tempNumber
  }
})
<template>
  <v-app>
    <v-main class="background">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import RegisterStoreModule from '@/mixins/RegisterStoreModule';
import globalStore from '@/store.js';
// import { mapGetters } from 'vuex';


export default {
  name: 'App',
  data() {
    return {
      classes: [{ main: true }, { main1: true }, { main2: true }, { main4: true }, { main3: true }, { main5: true }],
    }
  },
  computed: {
    // useClass() {
    //   // const random = Math.floor(Math.random() * this.classes.length);
    //   console.log('random', this.randomNumber)
    //   return this.classes[this.randomNumber]
    // }
  },
  mixins: [RegisterStoreModule],
  async created() {
    document.title = 'Sim Support'
    await this.registerStoreModule('global', globalStore);
    console.log('register is done')
  },
};

</script>

<style lang="scss">
@import '@/scss/variable.scss';
@import '@/scss/app.scss';

.background {
  // background-image: url('./assets/cellsimBG.jpg') no-repeat center center fixed !important;
  // background-image: url('https://t3.ftcdn.net/jpg/05/02/37/84/360_F_502378455_UH76V1lhBW0zHX8UozsW9LJDcS02QuTo.jpg');
  // background-size: cover;
  background: #F6F6F6 !important;
}

// .useClass {
//   background-image: linear-gradient(to bottom, #E8EAF6, #7986CB);
// }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sims.length !== 0)?_c('v-data-table',{staticClass:"mt-3 table-header",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.sims,"loading":_vm.loading,"items-per-page":_vm.sims.length},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"pointer",on:{"click":function($event){return _vm.openDetail(item)}}},[_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'MM/DD/YYYY LT')))]),(item.metadata && item.metadata.simInfo && item.metadata.simInfo.eSim)?_c('span',{staticClass:"ml-2"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","pill":""}},[_vm._v("E-SIM")])],1):_vm._e()]),_c('td',[(item && item.metadata && item.metadata.simInfo && item.metadata.simInfo.AddALineId)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"pink lighten-4"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add A Line ")],1):_vm._e(),(item.activationType === 'parent')?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"label":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-family-tree")]),_vm._v(" Family ")],1):_vm._e()],1),_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("simNumber")(item.serialNumber)))]),(item.plan)?_c('div',[_vm._v(_vm._s(item.plan))]):_vm._e()]),(item.metadata
          && item.metadata.activated
          && item.metadata.activated.phoneNumber)?_c('td',[_vm._v(_vm._s(_vm._f("USPhoneNumber")(item.metadata.activated.phoneNumber)))]):_c('td',[(item.status !== 'rejected')?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("Processing...")]):_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white","label":""}},[_vm._v("Rejected")])],1),(item.metadata
          && item.metadata.simInfo
          && item.metadata.simInfo.activation)?_c('td',[_vm._v(_vm._s(item.metadata.simInfo.activation))]):_c('td',[_vm._v("N/A")]),_c('td',[_c('v-chip',{staticClass:"text-capitalize",attrs:{"small":"","label":"","dark":"","color":_vm.color(item.status)}},[_vm._v(" "+_vm._s((item.status === 'activate') ? 'pending' : item.status)+" ")])],1)])]}}],null,false,3617018511)}):_vm._e(),(_vm.selectedPlan)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"15"}},[_c('v-card-title',{staticClass:"text-h5 black--text font-weight-bold"},[(_vm.selectedPlan.activationType === 'parent')?_c('v-chip',{staticClass:"font-weight-bold mr-1",attrs:{"label":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-family-tree")]),_vm._v(" Family ")],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{staticClass:"mx-2",attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Sim #")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("simNumber")(_vm.selectedPlan.serialNumber)))])]),(_vm.selectedPlan.metadata
            && _vm.selectedPlan.metadata.activated
            && _vm.selectedPlan.metadata.activated.phoneNumber)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Phone #")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("USPhoneNumber")(_vm.selectedPlan.metadata.activated.phoneNumber)))])]):_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Phone #")]),(_vm.selectedPlan.status !== 'rejected')?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("Processing...")]):_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white","label":""}},[_vm._v("Rejected")])],1),_c('v-flex',{attrs:{"xs12":""}},[(_vm.selectedLine)?_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.planHeaders,"items":_vm.selectedLine.data,"loading":_vm.loading,"items-per-page":_vm.selectedLine.data.length},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',{staticClass:"pointer"},[_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("simNumber")(item.serialNumber)))]),(item.plan)?_c('div',[_vm._v(_vm._s(item.plan))]):_vm._e()]),(item.metadata
                    && item.metadata.activated
                    && item.metadata.activated.phoneNumber)?_c('td',[_vm._v(_vm._s(_vm._f("USPhoneNumber")(item.metadata.activated.phoneNumber))+" ")]):_c('td',[(item.status !== 'rejected')?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v("Processing...")]):_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white","label":""}},[_vm._v("Rejected")])],1)])]}}],null,false,3015469425)}):_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7}}),_c('div',[_vm._v("Getting Other Number")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.detailDialog = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container grid-list-xl fluid>
    <v-alert v-if="setting && setting.alert && setting.alert.status" border="left" dismissible color="red darken-1"
      dark>
      {{ setting.alert.message }}
    </v-alert>

    <v-card-text class="py-0">
      <v-layout class="mt-8" wrap align-center justify-center justify-space-between>
        <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
          <BillPayment />
        </v-flex>
        <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
          <NewOrder />
        </v-flex>

        <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
          <v-card width="200" @click="activationSelecterModal = true" :ripple="false" height="200"
            class="button-30 rounded-xl" v-if="!$vuetify.breakpoint.xsOnly">
            <v-card-text style="height: 100%">
              <v-layout column fill-height align-center>
                <v-flex shrink>
                  <v-img height="100" contain src="@/assets/sim.png"></v-img>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex class="text-h6 font-weight-bold black--text">
                  Activations
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-card v-else width="100%" @click="(activationSelecterModal = !activationSelecterModal)" :ripple="false" height="85"
            class="button-30 rounded-xl">
            <v-card-text style="height: 100%">
              <v-layout row align-center>
                <v-flex shrink>
                  <v-img width="50" contain src="@/assets/sim.png"></v-img>
                </v-flex>
                <v-flex >
                  <span class="font-weight-bold black--text">Activations</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- <NewActivation @submit="reloadData" /> -->
        </v-flex>
        <!-- <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
          <v-card width="200" @click="supportSelecterModal = true" :ripple="false" height="200"
            class="button-30 rounded-xl" v-if="!$vuetify.breakpoint.xsOnly">
            <v-card-text style="height: 100%">
              <v-layout column fill-height align-center>
                <v-flex shrink>
                  <v-img height="100" contain src="@/assets/support.png"></v-img>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex class="text-h6 font-weight-bold black--text">
                  Support
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-card v-else width="100%" @click="(supportSelecterModal = !supportSelecterModal)" :ripple="false" height="85"
            class="button-30 rounded-xl">
            <v-card-text style="height: 100%">
              <v-layout row align-center>
                <v-flex shrink>
                  <v-img width="50" contain src="@/assets/support.png"></v-img>
                </v-flex>
                <v-flex>
                  <span class="font-weight-bold black--text">Support</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex> -->
        <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
          <v-card width="200" height="200" @click="historySelecterModal = true" :ripple="false"
            class="button-30 rounded-xl" v-if="!$vuetify.breakpoint.xsOnly">
            <v-card-text style="height: 100%">
              <v-layout column fill-height align-center>
                <v-flex shrink>
                  <v-img height="100" contain src="@/assets/dashboard.png"></v-img>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex class="text-h6 font-weight-bold black--text">
                  Reports
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-card v-else width="100%" @click="(historySelecterModal = !historySelecterModal)" :ripple="false" height="85"
            class="button-30 rounded-xl">
            <v-card-text style="height: 100%">
              <v-layout row align-center>
                <v-flex shrink>
                  <v-img width="50" contain src="@/assets/dashboard.png"></v-img>
                </v-flex>
                <v-flex>
                  <span class="font-weight-bold black--text">Reports</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>





    <v-layout row class="mt-5 ml-0 touch-view-container" fill-height>
      <v-flex xs12>
        <v-card height="100%" width="100%" class="rounded-xl" outlined style="border: #757575 1px solid !important;">
          <v-card-text>
            <RecentTransaction :reload="reload" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>


    <SupportSelecterDialog :modal="supportSelecterModal" @close="supportSelecterModal = false"></SupportSelecterDialog>
    <HistorySelecterDialog :modal="historySelecterModal" @close="historySelecterModal = false"></HistorySelecterDialog>
    <ActivationSelecterDialog :modal="activationSelecterModal" @close="activationSelecterModal = false">
    </ActivationSelecterDialog>
  </v-container>
</template>

<script>
import homeService from "@/modules/Home/service.js";
import RecentTransaction from "@/modules/Home/components/RecentTransaction"
import _ from "lodash";
import { mapGetters } from "vuex";
import Lottie from "@/components/lottie";
import * as animationDataApproved from "@/assets/lottie/1708-success.json";
const { validationMixin } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
import ActivationSelecterDialog from '@/modules/Activation/components/ActivationSelecterDialog.vue'
import BillPayment from '@/modules/BillPayment/components/Create.vue'
import HistorySelecterDialog from '@/modules/Reports/components/HistorySelecterDialog.vue'
import NewOrder from '@/modules/Order/components/Create.vue'
import SupportSelecterDialog from '@/modules/Tasks/components/SupportSelecterDialog.vue'
const notificationSound = new Audio(require('../../assets/audio/cellsim_notification_sound.wav'));

export default {
  name: "home",
  data() {
    return {
      activationSelecterModal: false,
      supportSelecterModal: false,
      defaultApprovedOptions: {
        animationData: animationDataApproved.default,
        loop: false,
        autoplay: true,
        // onComplete: this.$emit('close'),
      },
      reload: Math.random(),
      historySelecterModal: false,
      errorMessage: null,
      simInformation: null,
      typingTimer: null,
      doneTypingInterval: 1500,
      simLoading: false,
      simFound: false,
      ads1: null,
      ads2: null,
      activationSend: false,
      pinNumber: null,
      loading: false,
      description: null,
      loadingPlan: true,
      planPick: 0,
      simcard: null,
      planLabel: "New Activation",
      requestedPhoneNumber: null,
      accountNumber: null,
      accountPin: null,
      Plans: [],
      selectedPlan: null,
      setting: {
        alert: {
          message: null,
          status: false,
        },
      },
      AssignedContactId: null
    };
  },
  components: {
    Lottie,
    SupportSelecterDialog,
    ActivationSelecterDialog,
    BillPayment,
    RecentTransaction,
    // SimSwip,
    NewOrder,
    HistorySelecterDialog
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    simCardErrors() {
      const errors = [];
      if (!this.$v.simcard.$dirty) return errors;
      if (!this.$v.simcard.required) errors.push("Sim card number is required");
      if (!this.$v.simcard.minLength)
        errors.push("Number must be atleast 14 digits");
      return errors;
    },
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin Number must be atleast 6 digits");
      return errors;
    },
    selectPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      if (!this.$v.selectedPlan.required)
        errors.push("Select plan is required");
      return errors;
    },
    requestedPhoneNumberErrors() {
      const errors = [];
      if (!this.$v.requestedPhoneNumber.$dirty) return errors;
      if (!this.$v.requestedPhoneNumber.required)
        errors.push("Requested Phone number is required for port in");
      if (!this.$v.requestedPhoneNumber.minLength)
        errors.push("Phone number must be atleast 10 digits longer");
      return errors;
    },
    accountNumberErrors() {
      const errors = [];
      if (!this.$v.accountNumber.$dirty) return errors;
      if (!this.$v.accountNumber.required)
        errors.push("Account number is required for portin");
      if (!this.$v.accountNumber.minLength)
        errors.push("Account number must be atleast 4 digits or longer");
      return errors;
    },
    accountPinErrors() {
      const errors = [];
      if (!this.$v.accountPin.$dirty) return errors;
      if (!this.$v.accountPin.required) errors.push("Select plan is required");
      if (!this.$v.accountPin.minLength)
        errors.push("Acccount pin must be atleast 4 digits or longer");
      return errors;
    },
  },
  sockets: {
    // markedActivated(data) {
    // 	console.log('markActvate invoked');
    // 	if (data.AssignedContactId == this.AssignedContactId) {
    // 		this.$swal('Activated', `${data.serialNumber} was marked activated by owner`, 'success')
    // 	}
    // },
    // orderShipped(data) {
    // 	console.log('orderShipped', data);
    // 	if (data[0].ContactId == this.AssignedContactId) {
    // 		this.$swal('Order Shipped', `Order no ${data[0].id}has been shipped\nTracking Number:${data[0].metadata.shipping.trackingNumber}`, 'success');

    // 	}; notificationSound.play();
    // }
  },
  watch: {
    planPick(val) {
      if (val === 0) {
        this.planLabel = "New Activation";
      } else {
        this.planLabel = "Port in";
      }
    },
    // simcard: _.debounce(async function (val) {
    //   let newsim = val
    //   const lastSimNumber = val.slice(-1);
    //   // console.log("lastSimNumber", lastSimNumber);
    //   if (lastSimNumber === "f" || lastSimNumber === "F") {
    //   } else {
    //     newsim += "F";
    //   }

    //   console.log('sim card', newsim)
    // }, 100)
  },
  created() {
    this.adNumber();
    this.getPlan();

    let contact = JSON.parse(localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREFIX}contact`
    ))
    this.AssignedContactId = contact.id
  },
  methods: {
    reloadData() {
      console.log('submit got call')
      this.reload = Math.random()
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    // keyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchsim();
    //   }, this.doneTypingInterval);
    // },
    // keydown() {
    //   clearTimeout(this.typingTimer);
    // },
    adNumber() {
      let adList = [
        {
          id: 1,
          name: "ClockIn",
          url: "https://customclockin.com",
          image: "https://s3.amazonaws.com/4.0image/cellSimAds/ad2-min.png",
        },
        {
          id: 2,
          name: "CellSmart POS",
          url: "https://www.cellsmartpos.com/hardware",
          image: "https://s3.amazonaws.com/4.0image/cellSimAds/ad1.png",
        },
        {
          id: 3,
          name: "CellSmart Repair",
          url: "https://www.cellsmartpos.com/repair",
          image: "https://s3.amazonaws.com/4.0image/cellSimAds/repairAds.png",
        },
        {
          id: 3,
          name: "CellSmart Comm",
          url: "https://www.cellsmartpos.com/features/employee-commission-management/",
          image: "https://s3.amazonaws.com/4.0image/cellSimAds/comAd.png",
        },
      ];
      this.ads1 = adList[Math.floor(Math.random() * adList.length)];
      this.ads2 = adList[Math.floor(Math.random() * adList.length)];
    },
    signUpAds1() {
      window.open(this.ads1.url, "_blank");
    },
    signUpAds2() {
      window.open(this.ads2.url, "_blank");
    },
    reset() {
      this.simLoading = false
      this.simInformation = null
      this.errorMessage = null
      this.simFound = false
      this.planPick = 0;
      this.simcard = null;
      this.description = null;
      this.selectedPlan = null;
      this.pinNumber = null;
      this.planLabel = "New Activation";
      this.requestedPhoneNumber = null;
      this.accountNumber = null;
      this.accountPin = null;
      this.loading = false;
      this.activationSend = false;
      this.$v.$reset();
    },
    getPlan() {
      this.loadingPlan = true;
      return homeService
        .getSettings()
        .then((response) => {
          this.loadingPlan = false;
          this.Plans = response.plan;
          this.setting = response.setting;
        })
        .catch((error) => {
          console.log("error", error);
          this.loadingPlan = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    markActive(data) {
      return homeService
        .markActive(data)
        .then((response) => {
          console.log("markActive response", response);
          this.activationSend = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    markActive(data) {
      return homeService
        .markActive(data)
        .then((response) => {
          console.log("markActive response", response);
          this.activationSend = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    searchsim() {
      this.simLoading = true;
      let newsim = this.simcard;
      const lastSimNumber = this.simcard.slice(-1);
      // console.log("lastSimNumber", lastSimNumber);
      if (lastSimNumber === "f" || lastSimNumber === "F") {
      } else {
        newsim += "F";
      }
      console.log("newsim", newsim);
      return homeService
        .getSimInfo(newsim)
        .then((response) => {
          console.log("response.data.status", response);
          if (
            response.data.status === "activated" ||
            response.data.status === "activate"
          ) {
            // this.$swal(
            //   "Activation",
            //   "This sim is already activated. Check the sim number and try again ",
            //   "error"
            // );
            this.errorMessage =
              "This sim is already activated. Check the sim number and try again";
            this.simLoading = false;
            this.simFound = "notfound";
            return false;
          }
          this.simLoading = false;
          this.simFound = "found";
          this.simInformation = response.data;
        })
        .catch((error) => {
          this.simLoading = false;
          this.simFound = "notfound";
          this.errorMessage =
            "Invalid sim number please check sim number and try again.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false;
      }
      this.loading = true;

      const data = {
        serialNumber: this.simInformation.serialNumber,
        metadata: {
          simInfo: {
            createdAt: new Date(),
            plan: this.selectedPlan,
            pinNumber: this.pinNumber,
            activation: this.planLabel,
            description: this.description,
            Contact: this.getContact,
          },
        },
      };
      if (this.planPick === 1) {
        data.metadata.simInfo.activation = "Port In";
        data.metadata.simInfo.requestedPhoneNumber = this.requestedPhoneNumber;
        data.metadata.simInfo.accountNumber = this.accountNumber;
        data.metadata.simInfo.accountPin = this.accountPin;
      }
      console.log("result", data);
      // if (this.planPick === 0) {
      this.markActive(data);
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      simcard: {
        required,
        minLength: minLength(14),
      },
      selectedPlan: {
        required,
      },
      pinNumber: {
        required,
        minLength: minLength(6),
      },
      requestedPhoneNumber: {
        required: requiredIf(() => {
          if (this.planPick === 1) return true;
        }),
        minLength: minLength(10),
      },
      accountNumber: {
        required: requiredIf(() => {
          if (this.planPick === 1) return true;
        }),
        minLength: minLength(5),
      },
      accountPin: {
        required: requiredIf(() => {
          if (this.planPick === 1) return true;
        }),
        minLength: minLength(4),
      },
    };
  },
};
</script>

<style scoped>
.card-labels {
  display: block;
}

.touch-view-container {
  height: calc(100vh - 365px);
  width: 100%;
  overflow: auto;
}

.noscroll {
  overflow-y: scroll;
}

div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.selected {
  background: #0f0c60 !important;
}

.cong {
  color: #0f0c60;
}

</style>

